import { createSlice } from "@reduxjs/toolkit";
import { getVehicleUnloadingByID, getVehicleUnloadingList, vehicleUnloadingAdd, vehicleUnloadingEdit, vehicleUnloadingDelete, vehicleUnloadingAttachments } from "./vehicleUnloadingAction";

const vehicleUnloadingSlice = createSlice({
    name: "vehicleunloading",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        vehicleUnloadinglist: [],
        showCamera: false,
        vehicleImages: [],
        vehicleUnloadingitems: [],
        imageDataURL: null,
        cameraNumber: 0,
        balance_quantity:0,
        available_quantity:0,
        vehicleImages: [],
        vehicleImageID: [],
        vehicleUnloadingParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        capturedImage: {
            file: {},
            imageUrl: "",
            loading: false,
        },
        editUnloadingItems: {}
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        SetVehicleUnloadingParams: (state, action) => {
            return {
                ...state,
                vehicleUnloadingParams: {
                    ...state.vehicleUnloadingParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                rowdata: action.payload,
            }
        },
        setShowCamera: (state, action) => {
            return {
                ...state,
                showCamera: action.payload,
            }
        },
        setVehicleImages: (state, action) => {
            return {
                ...state,
                vehicleImages: [...state.vehicleImages, action.payload]
            }
        },
        setUnloadingItemsList: (state, action) => {
            return {
                ...state,
                vehicleUnloadingitems: [...state.vehicleUnloadingitems, action.payload]
            }
        },
        removeNullValuesFromImages: (state, action) => {
            return {
                ...state,
                vehicleImages: state.vehicleImages?.filter(item => item !== null)
            }
        },
        setImageDataURL: (state, action) => {
            return {
                ...state,
                imageDataURL: action.payload
            }
        },
        setUploadImage: (state, action) => {
            console.log("action", action.payload);
            return {
                ...state,
                capturedImage: {
                    file: action.payload.file,
                    imageUrl: action.payload.imageUrl,
                    loading: action.payload.loading
                },
            };
        },
        clearImages: (state, action) => {
            state.vehicleImages = [];
        },
        removeImages: (state, action) => {
            return {
                ...state,
                vehicleImages: state.vehicleImages?.filter(
                    (e) => e.id != action.payload
                ),
            };
        },
        setEditUnloadingItemsList: (state, action) => {
            return {
                ...state,
                vehicleUnloadingitems: state.vehicleUnloadingitems?.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        setEditUnloadingItems: (state, action) => {
            return {
                ...state,
                editUnloadingItems: action.payload,
            };
        },
        setVehicleUnloadingItemList: (state, action) => {
            return {
                ...state,
                vehicleUnloadingitems: action.payload
            }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                vehicleUnloadingitems: state.vehicleUnloadingitems.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setBalanceQuantity: (state, action) => {
            return {
                ...state,
                balance_quantity: action.payload,
            }
        },
        setAvaliableQuantity: (state, action) => {
            return {
                ...state,
                available_quantity: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVehicleUnloadingList.pending, (state, action) => {
                state.status = "getVehicleUnloadingList loading";
                state.loading = true;
            })
            .addCase(getVehicleUnloadingList.fulfilled, (state, action) => {
                state.status = "getVehicleUnloadingList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.vehicleUnloadingParams?.page_size
                );

                state.vehicleUnloadinglist = response.results;
                state.listCount = response.count;
                state.vehicleUnloadingParams = {
                    ...state.vehicleUnloadingParams,
                    no_of_pages: noofpages,
                };
                state.rowdata = {};
                state.vehicleImages = [];
                state.vehicleUnloadingitems = [];
            })
            .addCase(getVehicleUnloadingList.rejected, (state, action) => {
                state.status = "getVehicleUnloadingList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getVehicleUnloadingByID.pending, (state, action) => {
                state.status = "getVehicleUnloadingByID loading";
                state.loading = true;
            })
            .addCase(getVehicleUnloadingByID.fulfilled, (state, action) => {
                state.status = "getVehicleUnloadingByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.vehicleUnloadingitems = state.rowdata?.vehicleUnloadingitems;
                state.listItemCount = action.payload.vehicleUnloadingitems?.length;
                 action.payload.vehicleUnloadingitems?.map((val) => {
                    state.vehicleImages = [
                        val?.primary_loading_image,
                        val?.primary_unloading_image,
                        val?.secondary_loading_image,
                        val?.secondary_unloading_image,
                    ]
                })
                state.vehicleImages= state.vehicleImages.filter(item => item !== null)

            }
            )
            .addCase(getVehicleUnloadingByID.rejected, (state, action) => {
                state.status = "getVehicleUnloadingByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(vehicleUnloadingAdd.pending, (state, action) => {
                state.status = "vehicleUnloadingAdd loading";
                state.loading = true;
            })
            .addCase(vehicleUnloadingAdd.fulfilled, (state, action) => {
                state.status = "vehicleUnloadingAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.vehicleUnloadingitems = [];
            }
            )
            .addCase(vehicleUnloadingAdd.rejected, (state, action) => {
                state.status = "vehicleUnloadingAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(vehicleUnloadingEdit.pending, (state, action) => {
                state.status = "vehicleUnloadingEdit loading";
                state.loading = true;
            })
            .addCase(vehicleUnloadingEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "vehicleUnloadingEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.vehicleUnloadingitems = [];
            })
            .addCase(vehicleUnloadingEdit.rejected, (state, action) => {
                state.status = "vehicleUnloadingEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(vehicleUnloadingDelete.pending, (state, action) => {
                state.status = "vehicleUnloadingDelete loading";
                state.loading = true;
            })
            .addCase(vehicleUnloadingDelete.fulfilled, (state, action) => {
                state.status = "vehicleUnloadingDelete succeeded";
                state.loading = false
            }
            )
            .addCase(vehicleUnloadingDelete.rejected, (state, action) => {
                state.status = "vehicleUnloadingDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(vehicleUnloadingAttachments.pending, (state, action) => {
                state.status = "loading";
                state.loading = true;
            })
            .addCase(vehicleUnloadingAttachments.fulfilled, (state, action) => {
                const { response } = action.payload;
                state.status = "succeeded";
                state.loading = false;
                state.vehicleImages = [...state.vehicleImages, response];
                state.vehicleImageID = [...state.vehicleImageID, response.id]
            })
            .addCase(vehicleUnloadingAttachments.rejected, (state, action) => {
                state.status = "failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetVehicleUnloadingParams,
    isModelVisible,
    isDrawerVisible,
    setShowCamera,
    setAutoFillItems,
    setVehicleImages,
    setUnloadingItemsList,
    setImageDataURL,
    setUploadImage,
    removeImages,
    clearImages,
    setEditUnloadingItems,
    setEditUnloadingItemsList,
    setVehicleInwardItemsList,
    removeItems,
    changeParams,
    removeNullValuesFromImages,
    setVehicleUnloadingItemList,
    setBalanceQuantity,
    setAvaliableQuantity
} = vehicleUnloadingSlice.actions;

export default vehicleUnloadingSlice.reducer;